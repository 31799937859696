import { gql, useMutation } from "@apollo/client";
import { Button, LinearProgress, Paper, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import BasicCard from "components/card/BasicCard";
import { ErrorBox } from "components/error";
import { useSolutionKey } from "features/solution";
import { useQueryUseCaseList } from "features/use-case";
import { Formik, FormikErrors, FormikValues } from "formik";
import { debounce, isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import defaultIcon from "tile.svg";
import { isAsciiString } from "utils/addForm";
import { createRoute } from "utils/url";
import ChangesPreview from "./ChangesPreview";
import PublishForm from "./PublishForm";
import usePublishFormReducer, { State } from "./hooks/usePublishFormReducer";
import { useLazyQueryInputTableOptions } from "./hooks/useQueryInputTableOptions";
import {
  PublishUseCase,
  PublishUseCaseVariables
} from "./schema/PublishUseCase";

const mutationPublishUseCase = gql`
  mutation PublishUseCase(
    $solutionKey: Key!
    $useCaseDefinition: UseCaseDefinitionInput!
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          addUseCase(useCaseDefinition: $useCaseDefinition) {
            detail {
              version
              key
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => {
  const breakpointValue = 970;
  return createStyles({
    grid: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "25px 240px 80px auto",
      padding: theme.spacing(3),
      columnGap: theme.spacing(3),
      rowGap: theme.spacing(3),
      width: "80%",
      [theme.breakpoints.down(breakpointValue)]: {
        gridTemplateColumns: "100%"
      }
    },
    row1: {
      gridRow: "1 / span 1",
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1)
    },
    row2: { gridRow: "2 / span 1", gridRowStart: 2, gridRowEnd: 5 },
    row3: {
      [theme.breakpoints.up(breakpointValue)]: {
        gridRow: "3 / span 1",
        gridRowStart: 1,
        gridRowEnd: 2,
        marginTop: 0
      },
      margin: theme.spacing(2, 0, 1, 0),
      color: theme.palette.primary.main
    },
    row4: {
      [theme.breakpoints.up(breakpointValue)]: {
        gridRow: "4 / span 1",
        gridRowStart: 2,
        gridRowEnd: 3
      },
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      width: 350,
      height: 200
    },
    row5: {
      display: "inherit",
      [theme.breakpoints.up(breakpointValue)]: {
        gridRow: "5 / span 1",
        gridRowStart: 3,
        gridRowEnd: 4
      }
    },
    flex: {
      display: "flex",
      flexDirection: "column"
    },
    indicatorWrapper: {
      position: "sticky",
      width: "100%",
      zIndex: 1300
    },
    link: { display: "inline-flex", textDecoration: "none" },
    cancelButton: {
      color: "#FFFFFF",
      marginRight: theme.spacing(1),
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.8
      }
    },
    createButton: {
      color: "#FFFFFF",
      backgroundColor: theme.palette.primary.dark,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        opacity: 0.8
      }
    },
    disabledButton: {
      backgroundColor: "#C7CFD4 !important",
      color: "#68777B !important"
    },
    margin: {
      [theme.breakpoints.down(breakpointValue)]: { marginTop: theme.spacing(1) }
    },
    buttons: { marginTop: theme.spacing(5) }
  });
});

interface PublishFormWrapperProps {
  initialState?: State;
  updateUseCase?: boolean;
}

export default function PublishFormWrapper({
  initialState = {
    name: "",
    key: "",
    autoGeneratedKey: true,
    description: "",
    calculationRuleAddress: "",
    tags: [],
    transactionTable: null
  },
  updateUseCase = false
}: PublishFormWrapperProps) {
  const solutionKey = useSolutionKey();
  const classNames = useStyles({});

  const [
    publish,
    {
      loading: publishUseCaseLoading,
      error: publishUseCaseError,
      reset: resetPublishUseCaseError
    }
  ] = useMutation<PublishUseCase, PublishUseCaseVariables>(
    mutationPublishUseCase,
    {
      onCompleted: mutationResult => {
        const createdUseCase =
          mutationResult.solutions.solution?.useCases?.addUseCase?.detail;
        if (!createdUseCase) return;
        const { key, version } = createdUseCase;
        history.push(
          createRoute(
            `/solutions/${solutionKey}/create-use-case-version/${key}`
          )
        );
        history.push(
          createRoute(`/solutions/${solutionKey}/use-cases/${key}/${version}`)
        );
      }
    }
  );

  const { data: allUseCases } = useQueryUseCaseList(solutionKey);
  const [state, dispatch] = usePublishFormReducer(initialState);

  const history = useHistory();

  const {
    name,
    image,
    description,
    tags,
    calculationRuleAddress,
    key,
    transactionTable
  } = state;
  const initialValues = {
    name,
    key,
    description,
    calculationRuleAddress: "",
    transactionTable
  };
  const allUseCasesKeys =
    allUseCases?.solutions.solution?.useCases?.useCases?.map(
      ({ detail: { key, version } }) => ({ key, version })
    );

  const [
    lazyQueryInputTableOptions,
    {
      error: transactionTableOptionsError,
      loading: transactionTableOptionsLoading
    }
  ] = useLazyQueryInputTableOptions();
  const loading = publishUseCaseLoading || transactionTableOptionsLoading;
  const isExistedKey = (a: string) => allUseCasesKeys?.find(c => c.key === a);
  const [debouncedKey, setDebouncedKey] = useState(key);
  const delayed = useRef(debounce(setDebouncedKey, 500)).current;

  useEffect(() => {
    delayed(key);
  }, [delayed, key]);

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<FormikValues> = {};
    if (!updateUseCase) {
      if (values.key === "") {
        errors.key = "Web service Key is required";
      } else if (isExistedKey(values.key)) {
        errors.key = "This key already exists. You can publish a new version";
      } else if (/[^a-z0-9-]/.test(values.key)) {
        errors.key =
          "Key must only contain lowercase letters, digits and dashes";
      }
      if (
        values.name &&
        (values.key || key) &&
        !isExistedKey(values.key) &&
        !values.calculationRuleAddress
      ) {
        errors.calculationRuleAddress = "Calculation Rule is required";
      }
    } else {
      if (values.name && !values.calculationRuleAddress) {
        errors.calculationRuleAddress = "Calculation Rule is required";
      }
    }
    if (values.name === "") {
      errors.name = "Web service Name is required";
    } else if (!isAsciiString(values.name)) {
      errors.name = "Name must have only Ascii characters";
    }

    if (!Boolean(values.transactionTable)) {
      errors.transactionTable = "Transaction Table is required";
    }

    return errors;
  };
  return (
    <>
      <div className={classNames.indicatorWrapper}>
        {loading ? <LinearProgress /> : null}
      </div>
      <Paper className={classNames.grid}>
        <Typography className={classNames.row1}>NEW WEB SERVICE</Typography>
        <div className={classNames.row2}>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
              }, 500);
            }}
          >
            {({
              submitForm,
              isSubmitting,
              dirty,
              isValid,
              handleReset,
              ...restOfProps
            }) => {
              return (
                <div className={classNames.flex}>
                  <PublishForm
                    {...restOfProps}
                    solutionKey={solutionKey}
                    getTableOptions={lazyQueryInputTableOptions}
                    state={state}
                    dispatch={dispatch}
                    updateUseCase={updateUseCase}
                    version={isExistedKey(key)?.version}
                  />
                  <div className={classNames.buttons}>
                    <Link
                      className={classNames.link}
                      to={createRoute(`/solutions/${solutionKey}/use-cases`)}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        className={classNames.cancelButton}
                      >
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      disabled={loading || !dirty || !isValid}
                      id="publish-use-case"
                      variant="contained"
                      classes={{
                        root: classNames.createButton,
                        disabled: classNames.disabledButton
                      }}
                      className={classNames.createButton}
                      data-testid={
                        !updateUseCase
                          ? "publish-use-case"
                          : "publish-use-case-version"
                      }
                      onClick={() => {
                        submitForm();
                        if (!state.calculationRuleAddress) return;
                        publish({
                          variables: {
                            solutionKey,
                            useCaseDefinition: {
                              calculationRuleAddress:
                                state.calculationRuleAddress,
                              key: state.key,
                              name: state.name,
                              description: state.description,
                              transactionTable: state.transactionTable,
                              image: state.image,
                              tags: state.tags
                            }
                          }
                        });
                      }}
                    >
                      {!updateUseCase
                        ? "Create Web Service"
                        : "Create new version"}
                    </Button>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
        <Typography align="left" className={classNames.row3}>
          PREVIEW
        </Typography>
        <div className={classNames.row4} data-testid="preview">
          <BasicCard
            name={name}
            key={!isEmpty(key) ? key : "new-use-case"}
            tags={tags}
            icon={image ? image : defaultIcon}
            description={description}
          />
        </div>
        {calculationRuleAddress && debouncedKey && (
          <div className={classNames.row5}>
            <ChangesPreview
              solutionKey={solutionKey}
              useCaseKey={debouncedKey}
              calculationRuleAddress={calculationRuleAddress}
            />
          </div>
        )}
        {publishUseCaseError && (
          <div className={classNames.row5}>
            <ErrorBox
              apolloError={publishUseCaseError}
              title="Web service publish failed!"
              onClose={() => resetPublishUseCaseError()}
              closable
              maxHeight={updateUseCase ? 80 : 130}
            />
          </div>
        )}
        {transactionTableOptionsError && (
          <div className={classNames.row5}>
            <ErrorBox
              apolloError={transactionTableOptionsError}
              title="Loading transaction table options failed!"
              maxHeight={updateUseCase ? 80 : 130}
            />
          </div>
        )}
      </Paper>
    </>
  );
}
