import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import KeyAndNameInputs from "components/form/KeyAndNameInputs";
import { useFormStyles } from "features/use-case";
import {
  FormikErrors,
  FormikHandlers,
  FormikTouched,
  FormikValues
} from "formik";
import { Dispatch, FC, SyntheticEvent } from "react";
import { Action, State } from "./hooks/useAddSolutionReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: { minHeight: 78 },
    key: { minHeight: 85 },
    description: { minHeight: 102 },
    features: {
      border: "solid 1px rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      padding: theme.spacing(1)
    },
    formGroup: {
      alignItems: "flex-start"
    },
    label: {
      width: 186,
      justifyContent: "space-between"
    },
    typography: {
      color: "rgba(0, 0, 0, 0.6)",
      marginBottom: `${theme.spacing(1)} !important`
    }
  })
);

interface AddSolutionFormProps {
  state: State;
  dispatch: Dispatch<Action>;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const AddSolutionForm: FC<AddSolutionFormProps> = ({
  state,
  dispatch,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue
}) => {
  const classes = useFormStyles();
  const jss = useStyles();
  const {
    name,
    key,
    description,
    projectsEnabled,
    useCasesEnabled,
    autoGeneratedKey
  } = state;

  return (
    <form className={classes.form} noValidate>
      <Grid container direction="column">
        <KeyAndNameInputs
          value={key}
          name={name}
          autoGeneratedKey={autoGeneratedKey}
          onKeyChange={value => dispatch({ type: "SET_KEY", value })}
          onNameChange={value => dispatch({ type: "SET_NAME", value })}
          onKeyReset={() => dispatch({ type: "GENERATE_KEY" })}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          featureName="solution"
        />
        <Tooltip
          title={
            <div>
              Description of the purpose and scope of the solution.
              <br />
              Answers questions like: What is the solution good for? Who should
              use it? What are the objectives of the related project?
            </div>
          }
          placement="top-start"
        >
          <TextField
            id="outlined-description"
            label="Description"
            name="description"
            className={jss.description}
            required
            helperText={Boolean(touched.description) && errors.description}
            error={Boolean(touched.description && errors.description)}
            inputProps={{
              maxLength: 1000,
              "data-testid": "solution-description"
            }}
            multiline={true}
            minRows={2}
            maxRows={10}
            value={description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.persist();
              const value = e.target.value;
              dispatch({ type: "SET_DESCRIPTION", value });
              setFieldValue && setFieldValue("description", value);
              handleChange(e);
            }}
            onBlur={handleBlur}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              className: classes.label
            }}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline
              }
            }}
          />
        </Tooltip>
        <Typography className={jss.typography}>Features</Typography>
        <div className={jss.features}>
          <FormGroup className={jss.formGroup}>
            <FormControlLabel
              control={
                <Tooltip title="Enable SCS App stream">
                  <Checkbox data-testid="projects-enabled" />
                </Tooltip>
              }
              label="Projects Enabled"
              labelPlacement="start"
              className={jss.label}
              checked={projectsEnabled}
              onChange={(
                event: SyntheticEvent<Element, Event>,
                checked: boolean
              ) => {
                dispatch({ type: "PROJECTS_ENABLED", value: checked });
              }}
            />
            <FormControlLabel
              control={
                <Tooltip title="Enable creation and accessing web services">
                  <Checkbox data-testid="usecases-enabled" />
                </Tooltip>
              }
              label="Web Services Enabled"
              labelPlacement="start"
              className={jss.label}
              checked={useCasesEnabled}
              onChange={(
                event: SyntheticEvent<Element, Event>,
                checked: boolean
              ) => {
                dispatch({ type: "USECASES_ENABLED", value: checked });
              }}
            />
          </FormGroup>
        </div>
      </Grid>
    </form>
  );
};

export default AddSolutionForm;
