import { SettingsBackupRestore } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Tooltip,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useFormStyles } from "features/use-case";

import {
  FormikErrors,
  FormikHandlers,
  FormikTouched,
  FormikValues
} from "formik";

import { generateKeyFromName } from "utils/addForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 85
    },
    icon: { marginLeft: theme.spacing(1) }
  })
);

type KeyInputProps = {
  value: string;
  name: string;
  featureName: string;
  onNameChange: (name: string) => void;
  onKeyChange: (name: string) => void;
  onKeyReset: () => void;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  autoGeneratedKey: boolean;
  updateMode?: boolean;
};

const KeyAndNameInputs = ({
  value,
  name,
  featureName,
  handleChange,
  handleBlur,
  setFieldValue,
  onNameChange,
  onKeyChange,
  onKeyReset,
  errors,
  touched,
  autoGeneratedKey,
  updateMode = false
}: KeyInputProps) => {
  const classes = useFormStyles();
  const jss = useStyles();
  return (
    <>
      <Tooltip title={`The ${featureName} display name`} placement="top-start">
        <TextField
          id="outlined-name"
          label="Name"
          name="name"
          inputProps={{ maxLength: 64 }}
          error={Boolean(touched.name && errors.name)}
          value={name}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            onNameChange(value);
            setFieldValue && setFieldValue("name", value);
            if (autoGeneratedKey) {
              const generatedKey = generateKeyFromName(value);
              setTimeout(() => {
                setFieldValue && setFieldValue("key", generatedKey);
              });
            }
            handleChange(e);
          }}
          onBlur={handleBlur}
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            className: classes.label
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline
            }
          }}
          helperText={typeof errors.name === "string" && errors.name}
        />
      </Tooltip>
      <Tooltip
        title={`A (unique) user-friendly key which is used to identify a ${featureName}. Valid characters are lowercase letters, digits and dashes`}
        placement="top-start"
      >
        <TextField
          id="outlined-key"
          label="Key"
          name="key"
          className={jss.root}
          inputProps={{ maxLength: 64, "data-testid": "solution-key" }}
          error={Boolean(errors.key)}
          value={value}
          required
          helperText={
            typeof errors.key === "string" ? (
              errors.key
            ) : !updateMode ? (
              autoGeneratedKey ? (
                <Typography
                  variant="body2"
                  component="span"
                  className={classes.helperText}
                >
                  Key is generated from name
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  component="span"
                  className={classes.helperText}
                >
                  {"To reset the key to be generated from name press"}
                  <SettingsBackupRestore
                    fontSize="small"
                    className={jss.icon}
                  />
                </Typography>
              )
            ) : null
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.persist();
            const value = e.target.value;
            onKeyChange(value);
            handleChange(e);
          }}
          onBlur={handleBlur}
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            className: classes.label
          }}
          InputProps={{
            endAdornment: !autoGeneratedKey && (
              <InputAdornment position="end">
                <IconButton
                  data-testid="reset-Btn"
                  edge="end"
                  onClick={() => {
                    onKeyReset();
                    const key = generateKeyFromName(name);
                    setFieldValue && setFieldValue("key", key);
                  }}
                  size="large"
                >
                  <SettingsBackupRestore />
                </IconButton>
              </InputAdornment>
            ),
            classes: {
              notchedOutline: classes.notchedOutline
            }
          }}
        />
      </Tooltip>
    </>
  );
};
export default KeyAndNameInputs;
