import { ApolloClient, gql } from "@apollo/client";
import CustomStore from "devextreme/data/custom_store";
import { ApiKeys, ApiKeys_publicApi_apiKeys_apiKeys } from "./schema/ApiKeys";
import { RemoveApiKey, RemoveApiKeyVariables } from "./schema/RemoveApiKey";
import { UpdateApiKey, UpdateApiKeyVariables } from "./schema/UpdateApiKey";

export const queryApiKeys = gql`
  query ApiKeys {
    publicApi {
      apiKeys {
        apiKeys {
          id
          name
          value
          enabled
          transactionPackage
        }
      }
    }
  }
`;

export const queryApiKeyWithServiceProducts = gql`
  query ApiKeyWithServiceProducts($id: String!) {
    publicApi {
      apiKeys {
        apiKey(apiKeyId: $id) {
          id
          name
          value
          enabled
          transactionPackage
          attachedServiceProducts {
            solutionKey
            serviceProduct {
              id
              name
              transactionLimit
              usedTransactions
              limitType
              expirationDate
              services
              active
            }
          }
        }
      }
    }
  }
`;

export const mutationRemoveApiKey = gql`
  mutation RemoveApiKey($id: String!) {
    publicApi {
      apiKeys {
        removeApiKey(apiKeyId: $id) {
          id
        }
      }
    }
  }
`;

export const mutationAddApiKey = gql`
  mutation AddApiKey($apiKeyDefinition: ApiKeyDefinitionInput!) {
    publicApi {
      apiKeys {
        addApiKey(apiKeyDefinition: $apiKeyDefinition) {
          id
          name
          enabled
          transactionPackage
          value
        }
      }
    }
  }
`;

export const mutationUpdateApiKey = gql`
  mutation UpdateApiKey(
    $apiKeyId: String!
    $apiKeyDefinition: ApiKeyDefinitionInput!
  ) {
    publicApi {
      apiKeys {
        update(apiKeyId: $apiKeyId, apiKeyDefinition: $apiKeyDefinition) {
          id
          name
          value
          enabled
          transactionPackage
        }
      }
    }
  }
`;

type CustomStoreData = ApiKeys_publicApi_apiKeys_apiKeys;
type CustomStoreKey = ApiKeys_publicApi_apiKeys_apiKeys;

export function createStore(client: ApolloClient<object>) {
  return new CustomStore<CustomStoreData, CustomStoreKey>({
    async load() {
      const { error, data } = await client.query<ApiKeys>({
        query: queryApiKeys
      });
      if (error) throw new Error(error.message);
      return data.publicApi.apiKeys.apiKeys || [];
    },

    async update(key, values) {
      const updatedValues = { ...key, ...values };
      const { id, name, transactionPackage, enabled } = updatedValues;
      const { errors } = await client.mutate<
        UpdateApiKey,
        UpdateApiKeyVariables
      >({
        mutation: mutationUpdateApiKey,
        variables: {
          apiKeyId: id,
          apiKeyDefinition: {
            name,
            transactionPackage,
            enabled
          }
        }
      });
      if (errors) throw new Error(errors.join("\n"));
    },
    async remove(item) {
      const { errors } = await client.mutate<
        RemoveApiKey,
        RemoveApiKeyVariables
      >({
        mutation: mutationRemoveApiKey,
        variables: { id: item.id }
      });
      if (errors) throw new Error(errors.join("\n"));
    }
  });
}
