import { gql, useLazyQuery } from "@apollo/client";
import {
  InputTableOptions,
  InputTableOptionsVariables
} from "./schema/InputTableOptions";

const queryInputTables = gql`
  query InputTableOptions(
    $solutionKey: Key!
    $calculationRuleAddress: String!
    $useCaseKey: Key!
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey) {
            detail {
              nextVersion(calculationRuleAddress: $calculationRuleAddress) {
                releaseType
                version
                schema {
                  input {
                    name
                  }
                }
              }
            }
          }
          useCaseSchema(calculationRuleAddress: $calculationRuleAddress) {
            input {
              name
            }
          }
        }
      }
    }
  }
`;

export function useLazyQueryInputTableOptions() {
  return useLazyQuery<InputTableOptions, InputTableOptionsVariables>(
    queryInputTables
  );
}

export function extractInputSchemaTableNames(
  data: InputTableOptions | undefined
) {
  if (data === undefined) return [];

  const schema =
    data.solutions.solution?.useCases?.useCase?.detail.nextVersion?.schema ||
    data.solutions.solution?.useCases?.useCaseSchema;
  const tables = schema?.input.map(tables => tables.name) || [];
  return tables;
}
