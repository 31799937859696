import { gql, useQuery } from "@apollo/client";
import {
  QueryUseCaseByKey,
  QueryUseCaseByKeyVariables
} from "./schema/QueryUseCaseByKey";

export const queryUseCaseByKey = gql`
  query QueryUseCaseByKey($solutionKey: Key!, $useCaseKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey) {
            detail {
              description
              image
              key
              name
              tags
              version
              transactionTable
            }
          }
        }
      }
    }
  }
`;

export default function useQueryUseCaseByKey(
  variables: QueryUseCaseByKeyVariables
) {
  return useQuery<QueryUseCaseByKey, QueryUseCaseByKeyVariables>(
    queryUseCaseByKey,
    {
      variables
    }
  );
}
